.mass-autocomplete {
  position: relative;
}
.ac-container {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 999;
}
.ac-container .ac-menu {
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  background-color: white;
  box-shadow: 0 3px 7px 0px rgba(0, 0, 0, 0.15);
  margin: 0;
}
.ac-container .ac-menu .ac-menu-item {
  border-bottom: 1px dotted #ccc;
  line-height: 35px;
  margin: 0;
}
.ac-container .ac-menu .ac-menu-item:last-child {
  border-bottom: 0;
}
.ac-container .ac-menu .ac-menu-item:hover {
  background-color: #e0eaff;
}
.ac-container .ac-menu a {
  padding-left: 5px;
  display: block;
  color: inherit;
  text-decoration: none;
}
.ac-container .ac-menu .ac-state-focus {
  outline: none;
  background-color: #e0eaff;
}
